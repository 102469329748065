import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, debounceTime, filter, Observable } from 'rxjs';

import { DeviceInventoryLoadingStates } from '../api/device-discovery-api.service';

@Injectable({
	providedIn: 'root'
})
export class InventoryLoadingStateService {
	private currentState = new BehaviorSubject<DeviceInventoryLoadingStates>('WaitingToStart');
	private deviceEventMap = new Map<string, number>();
	private readonly numEventsToIndicateSnapshotReceived = 2;
	private numDevicesReceivedSnapshot = 0;

	constructor() {
		// This is a popcorn timer to protect against being stuck in the
		// "WaitingForSnapshots" state.
		this.currentState
			.asObservable()
			.pipe(
				filter((state) => state === 'WaitingForSnapshots'),
				debounceTime(15_000),
				takeUntilDestroyed()
			)
			.subscribe(() => {
				this.setState('Done');
			});
	}

	public inventoryLoadingState$(): Observable<DeviceInventoryLoadingStates> {
		return this.currentState.asObservable();
	}

	public setState(newState: DeviceInventoryLoadingStates): void {
		this.currentState.next(newState);
	}

	/**
	 * Adds the ID to the list of IDs to track, or increments the current
	 * event count by 1 if the ID is already being tracked.
	 * Then, updates the loading state to either 'Done' or 'WaitingForSnapshots'
	 * if necessary.
	 * @param id - The device's Node ID used as the key value.
	 * @returns - count of devices having received snapshot
	 */
	public track(id: string): number {
		let mapEntry = this.deviceEventMap.get(id);
		if (mapEntry === undefined) {
			this.deviceEventMap.set(id, 1);
		} else {
			mapEntry++;
			this.deviceEventMap.set(id, mapEntry);
			if (mapEntry >= this.numEventsToIndicateSnapshotReceived) {
				this.numDevicesReceivedSnapshot++;
			}
		}
		this.updateState();
		return this.numDevicesReceivedSnapshot;
	}

	/**
	 * Removes an entry from the container so it is no longer tracked..
	 * Then, updates the loading state to either 'Done' or 'WaitingForSnapshots'
	 * if necessary.
	 * @param id - The device's Node ID used as the key value.
	 * @returns - void
	 */
	public untrack(id: string): number {
		const mapEntry = this.deviceEventMap.get(id) ?? 0;
		if (mapEntry >= this.numEventsToIndicateSnapshotReceived) {
			this.numDevicesReceivedSnapshot--;
		}
		this.deviceEventMap.delete(id);
		this.updateState();
		return this.numDevicesReceivedSnapshot;
	}

	private updateState(): void {
		// transition to Done, if not already Done
		if (this.deviceEventMap.size === this.numDevicesReceivedSnapshot && this.currentState.value !== 'Done') {
			this.setState('Done');
			return;
		}

		// If still waiting for entries to receive snapshot, and we're in the
		// 'WaitingForSnapshots' state, re-emit the same state. We make sure
		// we're in the 'WaitingforSnapshot's state for the following reasons:
		// - we could still be in the 'Discovery` state if/when multiple batches
		//   are needed
		// - re-emitting the 'WaitingForSnapshot's event keeps a potential
		//   popcorn timer from expiring.
		if (
			this.deviceEventMap.size !== this.numDevicesReceivedSnapshot &&
			this.currentState.value === 'WaitingForSnapshots'
		) {
			this.setState('WaitingForSnapshots');
		}
	}
}
