import { Injectable, Inject, signal } from '@angular/core';
import { isEqual } from 'lodash-es';
import {
	map,
	Observable,
	tap,
	Subscription,
	takeUntil,
	Subject,
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged
} from 'rxjs';

import { DeviceRelationshipService } from '@shure/cloud/device-management/shared/services';
import { SubscriptionManager, SubscriptionManagerConfigCreate } from '@shure/cloud/shared/apollo';
import { InventoryDevice } from '@shure/cloud/shared/models/devices';
import { UpdateResponse } from '@shure/cloud/shared/models/http';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ApolloQueryErrorMapper } from '@shure/shared/angular/data-access/system-api/core';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { CloudDeviceApiService } from '../api/cloud-device-api.service';
import {
	DeviceDiscoveryApiService,
	DeviceInventoryEvent,
	DeviceInventoryLoadingStates
} from '../api/device-discovery-api.service';
import { DeviceStatusService } from '../api/device-status.service';
import { InventoryDevicesApiService } from '../api/inventory-devices-api.service';

import {
	NodeChangeType,
	InventoryDeviceByIdQueryGQL,
	InventoryDeviceSubscriptionGQL
} from './graphql/generated/cloud-sys-api';
import { mapInventoryDeviceFromSysApi } from './mappers/map-inventory-device';

export type InventoryLoadingState = {
	state: DeviceInventoryLoadingStates;
	percentComplete: number;
};

@Injectable({ providedIn: 'root' })
export class SysApiInventoryDevicesApiService implements InventoryDevicesApiService {
	public deviceInventory = new BehaviorSubject<InventoryDevice[]>([]);
	public deviceInventoryEvents = new Subject<DeviceInventoryEvent<InventoryDevice>['change']>();
	public deviceInventorySnapshotCounts = new BehaviorSubject<number>(0);

	// Public signal for the device inventory loading indicator. Set to true when initial loading begins
	// and false when we think we're initially loaded. Once set to false when initial loading is done
	// it will not be set to true again unless the user were to logout/in or refresh the page.
	public deviceInventoryLoading = signal(false);

	public inventoryLoadingState = signal<InventoryLoadingState>({
		state: 'WaitingToStart',
		percentComplete: 0
	});

	protected readonly logger: ILogger;

	private destroy$ = new Subject<void>();

	private readonly devicesSubscriptionManager = new SubscriptionManager({
		subscriptionType: 'inventory-devices',
		create: (config): Subscription => this.createDeviceSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	constructor(
		logger: ILogger,
		private readonly cloudDeviceService: CloudDeviceApiService,
		private readonly inventoryDeviceSubscriptionGQL: InventoryDeviceSubscriptionGQL,
		private readonly inventoryDeviceByIdQueryGQL: InventoryDeviceByIdQueryGQL,
		private readonly deviceDiscoveryService: DeviceDiscoveryApiService,
		private readonly deviceStatusService: DeviceStatusService,
		private readonly oktaService: OktaInterfaceService,
		private readonly deviceRelationshipService: DeviceRelationshipService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {
		this.logger = logger.createScopedLogger('DaiInventoryDevicesService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});

		combineLatest([
			this.deviceDiscoveryService.getDeviceLoadingState$(),
			this.getInventoryDevicesCount$(),
			this.deviceInventorySnapshotCounts.asObservable()
		])
			.pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
			.subscribe({
				next: ([loadingState, numDevices, numDevicesReceivedSnapshot]) => {
					this.inventoryLoadingState.update(() => {
						const newState = {
							state: loadingState,
							percentComplete: numDevices === 0 ? 0 : (numDevicesReceivedSnapshot / numDevices) * 100
						};
						this.logger.information('LoadingState progress.', '%o', newState);
						return newState;
					});
				}
			});
	}

	public getInventoryDevicesCount$(): Observable<number> {
		return this.getInventoryDevices$().pipe(map((devices) => devices.length));
	}

	public getInventoryDevices$(): Observable<InventoryDevice[]> {
		return this.deviceInventory.asObservable();
	}

	public getInventoryDeviceEvents$(): Observable<DeviceInventoryEvent<InventoryDevice>['change']> {
		return this.deviceInventoryEvents.asObservable();
	}

	public getInventoryDeviceSnapshotCount$(): Observable<number | undefined> {
		return this.deviceInventorySnapshotCounts.asObservable();
	}

	public getInventoryDevice$(deviceId: string): Observable<InventoryDevice> {
		return this.inventoryDeviceByIdQueryGQL
			.watch(
				{
					nodeId: deviceId,
					requestTags: this.appEnv.showTags ?? false,
					requestLicenseV3: this.appEnv.cdmFeatureFlags?.licenseV3 ?? false
				},
				{
					errorPolicy: 'ignore',
					// 'cache-and-network' results in an initial network query. This is temporarilly necessary
					// because the sever-based BE doesn't send snapshots for OFFLINE devices.
					// Change the fetch-policty to 'cache-only' when that issue is fixed.
					fetchPolicy: 'cache-and-network',
					returnPartialData: true
				}
			)
			.valueChanges.pipe(
				map((query) => {
					const device = query.data.node;
					if (device && 'isDevice' in device) {
						return mapInventoryDeviceFromSysApi(device, this.deviceStatusService);
					}
					throw ApolloQueryErrorMapper.getError(query);
				})
			);
	}

	public setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setMute()', 'Setting mute', { deviceId, mute });
		return this.cloudDeviceService.setMute(deviceId, mute);
	}

	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setIdentify()', 'Setting identify', { deviceId, identify });
		return this.cloudDeviceService.setIdentify(deviceId, identify);
	}

	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setDeviceName()', 'Setting device name', { deviceId, name });
		return this.cloudDeviceService.setDeviceName(deviceId, name);
	}

	private initService(): void {
		this.logger.information('initService', 'user logged in, initializating service');
		this.destroy$ = new Subject();

		this.getInventoryDeviceEvents$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((event) => {
				if (event && (event.type === 'added' || event.type === 'updated')) {
					this.deviceRelationshipService.registerProxiedDevices(event.device);
					this.devicesSubscriptionManager.register([event.device.id]);
				} else if (event?.type === 'removed' && event.device.id) {
					this.devicesSubscriptionManager.deregister(event?.device.id);
					this.deviceRelationshipService.removeDevice(event?.device.id);
				}
			});

		this.getDeviceInventoryEvents();
	}

	private suspendService(): void {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.destroy$.next();
		this.destroy$.complete();
		this.devicesSubscriptionManager.deregisterAll();
	}

	private getDeviceInventoryEvents(): void {
		this.deviceDiscoveryService
			.getDiscoveredDevicesByQuery$<InventoryDevice>(
				(id) => this.getInventoryDevice$(id).pipe(takeUntil(this.destroy$)),
				(_device) => true // filter function (don't remove any)
			)
			.pipe(
				tap((event) => {
					this.deviceInventory.next(event.allDevices);
					this.deviceInventoryEvents.next(event.change);
					this.deviceInventorySnapshotCounts.next(event.numDevicesReceiviedSnapshot);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	private createDeviceSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		const subscriptionTypes = [
			NodeChangeType.DeviceAvailablePackages,
			NodeChangeType.DeviceBatteryLevel,
			NodeChangeType.DeviceIdentify,
			NodeChangeType.DeviceLicenseV3,
			NodeChangeType.DeviceMicStatus,
			NodeChangeType.DeviceName,
			NodeChangeType.DeviceProxiedDevices,
			NodeChangeType.DeviceUpdateProgress,
			NodeChangeType.DeviceRoom,
			NodeChangeType.DeviceTags
		];

		return this.inventoryDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: subscriptionTypes,
					requestTags: this.appEnv.showTags ?? false,
					requestLicenseV3: this.appEnv.cdmFeatureFlags?.licenseV3 ?? false
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'network-only' //  we always want subscription data from the server
				}
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				next: () => {},
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				complete: () => {},
				error: (error) => {
					this.logger.error(
						'inventoryDeviceSubscriptionGQL',
						'Encountered error',
						JSON.stringify({ id, error })
					);
					retryCallback();
				}
			});
	}
}
